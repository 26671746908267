<template>
  <div class="main">
    <div class="outer">
      <div class="infors">
        <div class="older-num">
          <span>{{
            dataList.managementInfo.elderlyPopulationHealthCount
          }}</span>
          <span>社区内老龄人口健康档案建档人数</span>
        </div>
        <div class="older-rate">
          <span>{{ dataList.managementInfo.totalRatio }}%</span>
          <span>占比总人数</span>
        </div>
      </div>
      <div class="top-disease">
        <div class="title">
          <span>排名前五慢性病</span>
          <span>患病人数</span>
          <span>占比建档人数</span>
        </div>
        <div v-if="dataList.managementInfo.diseases.length">
          <div
            class="disease"
            v-for="(item, index) in dataList.managementInfo.diseases"
            :key="index"
          >
            <div class="name">
              <span class="name_index">{{ index + 1 }}</span>
              <span class="name_ase">{{ item.diseaseName }}</span>
            </div>
            <div class="num">{{ item.diseaseCount }}</div>
            <div class="rate">{{ item.diseaseRatio }}</div>
          </div>
        </div>
        <div v-else class="noMessage">暂无数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import dachuanData from '@/assets/dachuanData/index.js'
export default {
  data() {
    return {
      dataList: {
        managementInfo: {
          elderlyPopulationHealthCount: 0, // 社区内老龄人口健康档案建档人数
          totalRatio: 0, // 占比总人数
          diseases: [
            // {
            //   diseaseName: '关节炎',
            //   diseaseCount: 189,
            //   diseaseRatio: 34.93,
            // },
          ],
        },
      },
      dataListDs: dachuanData.ds_data.dataList,
      dataListGc: dachuanData.gc_data.dataList,
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    },
  },
  mounted() {
    this.statisticalDataCollection()
  },
  methods: {
    statisticalDataCollection() {
      if (this.type == 'ds') {
        this.dataList = this.dataListDs
      } else {
        this.dataList = this.dataListGc
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  background: url('~@/assets/images/institution_images/healthManagmentBg.png') 0
    0 no-repeat;
  background-size: 100% 100%;
  height: 9.6rem;
  width: 100%;
  margin-top: 0.41rem;
  overflow: hidden;
}
.outer {
  margin-top: 1.83rem;
  padding: 0 0.41rem 0.41rem;
  display: grid;
  grid-template-columns: 5.41rem 17.8rem;
  gap: 0.41rem;
}
.infors {
  display: flex;
  flex-direction: column;
  height: 7.5rem;
}
.infors .older-num {
  width: 5.41rem;
  height: 3.66rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.41rem;
}
.infors .older-rate {
  width: 5.41rem;
  height: 3rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infors span {
  text-align: center;
}
.infors .older-num :first-child,
.infors .older-rate :first-child {
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
  line-height: 1.45rem;
  margin: 0.33rem 0 0.25rem;
}
.infors .older-num :last-child,
.infors .older-rate :last-child {
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.66rem;
  padding: 0 0.375rem;
}
.top-disease {
  width: 17.83rem;
  height: 7.2rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
}
.title {
  display: flex;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.5rem;
  border-radius: 0.08rem;
  border-bottom: 0.04rem solid #213060;
  padding: 0.33rem 0.58rem;
}
.title :first-child {
  flex: 4;
}
.title :nth-child(2),
.title :last-child {
  flex: 2;
}

.noMessage {
  font-size: 1.2rem;
  color: #2d3769;
  text-align: center;
  margin-top: 2rem;
}
.disease {
  display: flex;
  padding: 0.41rem 0.58rem 0rem;
}
.disease .name {
  flex: 4;
}
.disease .num,
.disease .rate {
  flex: 2;
  color: #b4d3fe;
}
.disease .name .name_index {
  display: inline-block;
  width: 0.66rem;
  height: 0.66rem;
  background: #ff4d3e;
  border-radius: 0.16rem;
  text-align: center;
  font-size: 0.58rem;
  font-weight: 400;
  color: #222222;
  line-height: 0.66rem;
}
.disease .name .name_ase {
  font-size: 0.58rem;
  font-weight: 400;
  color: #ff4d3e;
  line-height: 0.58rem;
  margin-left: 0.41rem;
}
.disease:nth-of-type(2) .name .name_index {
  background: #ff7a3e;
}
.disease:nth-of-type(3) .name .name_index {
  background: #ff9c3e;
}
.disease:nth-of-type(4) .name .name_index {
  background: #ffd23e;
}
.disease:nth-of-type(5) .name .name_index {
  background: #f3ff3e;
}
.disease:nth-of-type(2) .name .name_ase {
  color: #ff7a3e;
}
.disease:nth-of-type(3) .name .name_ase {
  color: #ff9c3e;
}
.disease:nth-of-type(4) .name .name_ase {
  color: #ffd23e;
}
.disease:nth-of-type(5) .name .name_ase {
  color: #f3ff3e;
}
</style>
